import OSS from 'ali-oss';

// 日期格式化-无分割线
const fomatterDate_YYYYMMdd2 = (dateStr) => {
	let date = new Date(dateStr);
	let Y = date.getFullYear(),
		M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1),
		D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
	// h = date.getHours() + ':',
	// m = date.getMinutes() + ':',
	// s = date.getSeconds();
	return Y+"" + M+"" + D;
}

const root_folder = "storage2/";

export const ossConfig = {
	// return {
	// region: 'oss-cn-hongkong',
	region: 'oss-accelerate',
	// accessKeyId: 'LTAI5t9pUsVQBnwyGgeGGuc4',
	// accessKeySecret: 'XljA667qnWvCmtwchv0mfyQPevtUsw',
	//2023.05.19
	accessKeyId: 'LTAI5tMPbxqCUDpaxdiEWbqc',
	accessKeySecret: 'BYmS5ZcgCQYmM0p38SBtsvA7Exfg5c',
	// bucket: bucket,
	// bucket: "hytx-oss-wh",
	//2024.10.22
	bucket: "hytx-oss-label",
	// endpoint: 'oss-cn-hongkong.aliyuncs.com',
	endpoint: 'oss-accelerate.aliyuncs.com',
	domainName: 'https://attachment.hytx.com',//代理的域名，存储到服务器的域名url，防止打开文件就下载
	// endpoint: 'hytx-oss-wh.oss-accelerate.aliyuncs.com',//加速
	secure: true //https
	// }
}

export const ossClient = () => {
	// let config = ossConfig;
	// config.bucket = bucket;
	return new OSS(ossConfig);
}


/**
 * 
 * oss 上传
 * 
 * option:{
		folder:"storage/label/",
	 	fileKey:"lable_trucking",
	 	fileId: this.fileId,
	 	file: base64toFile(base64,fileName,'application/pdf') 
 }
*/
export const ossUpload = async (option) => {

	let client = ossClient();
	// let config = ossConfig;
	// config.bucket = bucket;
	// return new OSS(config);
	let file = option.file;
	console.log('option.file', option.file);
	//
	// let bucket = this.bucket;
	let relativePath = '';
	// let relativePathArr = [this.folder, this.fileKey, this.fileId, new Date().getTime(), file.name.substr(file.name.lastIndexOf('.')).toLowerCase()];
	//替换影响链接的字符
	// let fileName = file.name ? file.name.replace(/(\+|\%|\#|\&|\\|\/|\=|\?|\:|\s)/g, "_") : '';
	// let relativePathArr = [option.folder, option.fileKey, option.fileId, new Date().getTime(), fileName];
	// relativePath = relativePathArr.join('_');

	//替换影响链接的字符
	let fileName = file.name ? file.name.replace(/(\+|\%|\#|\&|\\|\/|\=|\?|\:|\s)/g, "_") : '';
	let relativePathArr = ['_'+option.fileId, fomatterDate_YYYYMMdd2(new Date().getTime()),new Date().getTime()];
	relativePath = root_folder + option.folder + relativePathArr.join('/_') + fileName;

	// let fileName = file.name ? file.name.replace(/(\+|\%|\#|\&|\\|\/|\=|\?|\:|\s)/g, "_") : '';
	// // let ymd = this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime());
	// let relativePathArr = [ '_'+this.fileId,this.fileKey, this.$Utils.fomatterDate_YYYYMMdd2(new Date().getTime()), new Date().getTime()+'_'];
	// relativePath = this.folder + relativePathArr.join('/_') + fileName;

	try {
		// 分片上传文件
		let ret = await client.multipartUpload(relativePath, file, {
			progress: async p => {
				console.log(p);
				// let e = {};
				// e.percent = p * 100;
				// option.onProgress(e);
				// this.showProgress = true;
				// this.progress = Math.floor(p * 100);
				// if (100 == this.progress) {
				// 	// vm.loading = false;
				// }
			}
		});
		console.log(ret);
		return ret;
		// if (ret.res.statusCode === 200) {
		// 	console.log('onSuccess');
		// 	//调用此处，执行两次
		// 	// option.onSuccess(ret)
		// 	return ret;
		// } else {
		// 	// vm.loading = false;
		// 	// option.onError(this.$t('module.upload.fail_upload'));
		// }
	} catch (error) {
		console.error(error);
		// this.loading = false;
		// option.onError(this.$t('module.upload.fail_upload') + 'code:1');
		// this.$error(error.message);
	}

}